import React from 'react';
import 'antd/dist/reset.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/Auth/PrivateRoute';
import PublicRoute from './components/Auth/PublicRoute';
import { AuthProvider } from './context/AuthProvider';
import Accounts from './pages/Accounts';
import { AuthenticatedLayout } from './components/Layout';
import { authCookie, setAuthHeaders } from './utils';
import { Auth0ProviderWithNavigate } from './context/Auth0Provider';

function App() {
  if (authCookie.get()) {
    setAuthHeaders();
  }
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<PublicRoute />} />
            <Route
              path="/"
              element={
                <PrivateRoute
                  component={Accounts}
                  layout={AuthenticatedLayout}
                />
              }
            />
          </Routes>
        </AuthProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
}

export default App;
