import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, notification } from 'antd';
import useAccountUpdate from '../../hooks/accounts/useAccountUpdate';
import AddEditForm from './AddEditForm';
import { ClientFeatures } from '../Features/ClientFeatures';
import { css } from '@emotion/css';
import { dateForServer } from '../../utils';

const EditAccountModal = ({
  accountsType,
  isOpen,
  onClose,
  afterSave,
  meters,
  clientOptions,
  selectedRecord,
}) => {
  const [{ form, isSaving }, { updateAccount }] = useAccountUpdate({
    accountsType,
    selectedRecordId: selectedRecord.id,
  });
  const isAccount = accountsType === 'accounts';

  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  const submitHandler = async () => {
    try {
      const values = await form.validateFields();
      if (values.trial_end_date !== null)
        values.trial_end_date = dateForServer(values.trial_end_date);
      await updateAccount(values);
      notification.success({
        placement: 'bottomRight',
        description: `${
          isAccount ? 'Account' : 'Trial'
        } has been updated successfuly.`,
      });
      afterSave();
    } catch (err) {
      if (!err.errorFields) {
        notification.error({
          placement: 'bottomRight',
          description: `There was an error updating ${
            isAccount ? 'Account' : 'Trial'
          }`,
        });
      }
    }
  };

  return (
    <Modal
      title={`Edit ${isAccount ? 'Account' : 'Trial'}`}
      open={isOpen}
      destroyOnClose
      width={800}
      onCancel={closeHandler}
      footer={
        <div className={css`display: flex; justify-content: end`}>
          <ClientFeatures client={selectedRecord.id} />
          <Button key="cancel" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={isSaving}
            onClick={submitHandler}
          >
            {`Save ${isAccount ? 'Account' : 'Trial'}`}
          </Button>
        </div>
      }
    >
      <AddEditForm
        meters={meters}
        clientOptions={clientOptions}
        form={form}
        accountsType={accountsType}
        initialValues={selectedRecord}
        isEdit
      />
    </Modal>
  );
};

EditAccountModal.propTypes = {
  accountsType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

EditAccountModal.defaultProps = {
  isOpen: false,
};

export default EditAccountModal;
