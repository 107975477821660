import React from 'react';
import { Empty, Input, List, Modal, notification } from 'antd';
import { css } from '@emotion/css';
import { ClientFeatureItem } from './ClientFeatureItem';
import { ClientFeatureAddForm } from './ClientFeatureAddForm';
import ClientFeaturesAPI from '../../api/client-features';

export const ClientFeaturesModal = ({
  features,
  onChange,
  open,
  onClose,
  client,
}) => {
  const [filter, setFilter] = React.useState('');

  const onItemChange = async (item) => {
    try {
      const updatedFeature = await ClientFeaturesAPI.update({
        client,
        id: item.id,
        enabled: item.enabled,
      });
      const index = features.findIndex((i) => i.id === item.id);
      const newFeatures = [...features];
      newFeatures[index] = updatedFeature;
      onChange(newFeatures);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'Error updating client feature',
      });
    }
  };

  const onItemDelete = async (item) => {
    try {
      await ClientFeaturesAPI.destroy({ client, id: item.id });
      const newFeatures = features.filter((i) => i.id !== item.id);
      onChange(newFeatures);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'Error deleting client feature',
      });
    }
  };

  const onItemAdd = async (data) => {
    try {
      const addedFeature = await ClientFeaturesAPI.create({ client, ...data });
      const newFeatures = [addedFeature, ...features];
      onChange(newFeatures);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'Error adding client feature',
      });
    }
  };

  return (
    <Modal
      title="Client Features"
      open={open}
      onCancel={onClose}
      centered
      footer={null}
    >
      <div className={css`margin: 16px 0 24px;`}>
        {features.length > 0 ? (
          <>
            <div className={css`margin: 16px 0;`}>
              <Input.Search
                placeholder="Filter client features"
                onSearch={setFilter}
              />
            </div>
            <div
              className={css`height: 375px; overflow-y: auto; background: #fafafa; padding: 4px; border-radius: 4px;`}
            >
              <List
                dataSource={
                  filter
                    ? features.filter((i) =>
                        i.feature.name
                          .toLowerCase()
                          .includes(filter.toLowerCase()),
                      )
                    : features
                }
                rowKey="id"
                renderItem={(item) => (
                  <ClientFeatureItem
                    item={item}
                    onChange={onItemChange}
                    onDelete={onItemDelete}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <Empty description="No client features added" />
        )}
      </div>
      <fieldset
        className={css`border-radius: 4px; border: 1px solid #d9d9d9; padding: 12px;`}
      >
        <legend
          className={css`font-size: 12px; font-weight: 500; padding: 0 8px; display: inline-block; width: auto;`}
        >
          Add client feature
        </legend>
        <ClientFeatureAddForm
          onAdd={onItemAdd}
          appliedFeatures={features.map((f) => f.feature)}
        />
      </fieldset>
    </Modal>
  );
};
