import Cookies from 'js-cookie';
import axios from 'axios';
import { TOKEN_COOKIE_KEY, TOKEN_COOKIE_EXPIRE } from './consts';
import dayjs from 'dayjs';

export const authCookie = {
  set: (value) => {
    Cookies.set(TOKEN_COOKIE_KEY, value, { expires: TOKEN_COOKIE_EXPIRE });
  },
  get: () => Cookies.get(TOKEN_COOKIE_KEY),
  remove: () => {
    Cookies.remove(TOKEN_COOKIE_KEY);
  },
};

export const setAuthHeaders = () => {
  axios.defaults.headers = {
    Authorization: `jwt ${authCookie.get()}`,
  };
};

export const clearAuthHeaders = () => {
  axios.defaults.headers = null;
};

export const dateForServer = (value) =>
  value ? dayjs(value).format('YYYY-MM-DD') : null;

export const dateForInput = (value) => (value ? dayjs(value) : null);

export const templateFormat = (fmt, obj, named) => {
  if (named || !Array.isArray(obj)) {
    return fmt.replace(/%\(\w+\)s/g, (match) => {
      return String(obj[match.slice(2, -2)]);
    });
  }
  return fmt.replace(/%s/g, () => {
    return String(obj.shift());
  });
};
