import React, { useEffect } from 'react';
import { Button, Divider, notification, Select, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FeaturesAPI from '../../api/features';
import axios from 'axios';
import { css } from '@emotion/css';

export const FeaturesLookup = ({ value, onChange, exclude }) => {
  const [allOptions, setAllOptions] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  const fetchOptions = async () => {
    setLoading(true);
    try {
      const results = await FeaturesAPI.lookup();
      setAllOptions(results);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'An error occurred while fetching features',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOptions(
      allOptions
        .filter((item) => !exclude.find((i) => i.id === item.id))
        .map((item) => ({ value: item.id, label: item.name })),
    );
  }, [allOptions, exclude]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const addItem = async (e) => {
    e.preventDefault();
    try {
      setAdding(true);
      const createdItem = await FeaturesAPI.create({ name: searchValue });
      const newOption = { value: createdItem.id, label: createdItem.name };
      setOptions([...options, newOption]);
      setSearchValue('');
      onChange(createdItem.id);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'An error occurred while creating feature',
      });
    } finally {
      setAdding(false);
    }
  };

  const canBeAdded =
    searchValue && !options.some((f) => f.label === searchValue);

  return (
    <Select
      placeholder="Select or create a feature"
      options={options}
      loading={loading}
      value={value}
      onChange={onChange}
      showSearch
      searchValue={searchValue}
      onSearch={setSearchValue}
      optionFilterProp="label"
      onKeyDown={(e) => {
        if (
          !adding &&
          e.key === 'Enter' &&
          canBeAdded &&
          !options.filter((f) =>
            f.label.toLowerCase().includes(searchValue.toLowerCase()),
          ).length
        ) {
          e.preventDefault();
          addItem(e);
        }
      }}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {canBeAdded && (
              <>
                <Divider className={css`margin: 4px 0;`} />
                <Space>
                  <Button
                    type="link"
                    onClick={addItem}
                    icon={<PlusOutlined />}
                    loading={adding}
                  >
                    <span>
                      Add <Typography.Text mark>{searchValue}</Typography.Text>{' '}
                      as a new feature
                    </span>
                  </Button>
                </Space>
              </>
            )}
          </>
        );
      }}
    />
  );
};
