import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const options = [
  { label: 'Accounts', value: 'accounts' },
  { label: 'Trials', value: 'trials' },
];

const AccountsSwitch = ({ value, onChange }) => {
  return (
    <Radio.Group
      optionType="button"
      options={options}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  );
};
AccountsSwitch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AccountsSwitch;
