import React from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../context/AuthProvider';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useAuthContext();
  if (auth.isAuth0Authenticated && auth.isBPAuthenticated && auth.getToken())
    return (
      <Layout>
        <Component {...rest} />
      </Layout>
    );

  if (auth.isBPAuthenticated) auth.bpLogout();
  if (auth.isAuth0Authenticated)
    auth.auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  else
    auth.loginWithRedirect({
      appState: {
        returnTo: '/login',
      },
    });
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
};

export default PrivateRoute;
