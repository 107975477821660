import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, notification } from 'antd';
import useAccountCreate from '../../hooks/accounts/useAccountCreate';
import AddEditForm from './AddEditForm';
import { dateForServer } from '../../utils';

const AddAccountModal = ({
  accountsType,
  meters,
  clientOptions,
  isOpen,
  onClose,
  afterSave,
}) => {
  const [{ form, isSaving }, { createAccount }] = useAccountCreate({
    accountsType,
  });
  const isAccount = accountsType === 'accounts';

  const defaultMeters = meters?.length
    ? meters.filter((meter) => meter.default).map((option) => option.id)
    : [];

  const demoClient = clientOptions?.find((o) => o.default_demo_data_source);

  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  const submitHandler = async () => {
    try {
      const values = await form.validateFields();
      if (values.demo_data && values.source_client_name === undefined) {
        notification.error({
          placement: 'bottomRight',
          description: 'Demo data source is not selected!',
        });
        return;
      }
      if (!isAccount) {
        values.is_trial = true;
        values.source_client_name = values.source_client_name?.label;
      }
      if (values.trial_end_date !== null)
        values.trial_end_date = dateForServer(values.trial_end_date);
      await createAccount(values);
      notification.success({
        placement: 'bottomRight',
        description: `${
          isAccount ? 'Account' : 'Trial'
        } has been created successfuly.`,
      });
      afterSave();
      closeHandler();
    } catch (err) {
      if (!err.errorFields) {
        notification.error({
          placement: 'bottomRight',
          description: `There was an error creating ${
            isAccount ? 'Account' : 'Trial'
          }`,
        });
      }
    }
  };

  return (
    <Modal
      title={`Add ${isAccount ? 'Account' : 'Trial'}`}
      open={isOpen}
      onCancel={closeHandler}
      width={800}
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={closeHandler}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSaving}
          onClick={submitHandler}
        >
          {`Save ${isAccount ? 'Account' : 'Trial'}`}
        </Button>,
      ]}
    >
      <AddEditForm
        form={form}
        accountsType={accountsType}
        meters={meters}
        clientOptions={clientOptions}
        initialValues={{
          meters: defaultMeters,
          source_client_name: demoClient
            ? {
                disabled: undefined,
                key: demoClient.id,
                label: demoClient.name,
              }
            : null,
        }}
      />
    </Modal>
  );
};

AddAccountModal.propTypes = {
  accountsType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

AddAccountModal.defaultProps = {
  isOpen: false,
};

export default AddAccountModal;
