import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Switch } from 'antd';
import { FeaturesLookup } from './FeaturesLookup';

export const ClientFeatureAddForm = ({ onAdd, appliedFeatures }) => {
  const [adding, setAdding] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setAdding(true);
      await onAdd(values);
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setAdding(false);
    }
  };

  return (
    <Form form={form} onFinish={onFinish} disabled={adding}>
      <Form.Item
        name="feature"
        label="Feature"
        requiredMark="optional"
        rules={[{ required: true, message: 'Please select a feature' }]}
      >
        <FeaturesLookup exclude={appliedFeatures} />
      </Form.Item>
      <Form.Item
        name="enabled"
        label="Enabled"
        valuePropName="checked"
        initialValue={false}
      >
        <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
      </Form.Item>
      <Button htmlType="submit" icon={<PlusOutlined />}>
        Add
      </Button>
    </Form>
  );
};
