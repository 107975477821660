import React from 'react';
import { Button, Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../context/AuthProvider';
import Logo from '../Logo';

const { Header } = Layout;

const StyledLogo = styled.div`
  float: left;
  width: 120px;
  height: 31px;
  margin: 8px 24px 16px 0;
`;

const StyledHeader = styled(Header)`
  background-color: #fff;
`;

const StyledMenu = styled(Menu)`
  width: 60%;
`;

const StyledUserInfo = styled.div`
  float: right;
`;

const StyledLogoutButton = styled(Button)``;

const NavMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bpUser, bpLogout, auth0Logout } = useAuthContext();

  const logoutHandler = () => {
    bpLogout();
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <StyledHeader>
      <StyledLogo>
        <Logo />
      </StyledLogo>
      <StyledUserInfo>
        <UserOutlined /> {bpUser.full_name}
        <StyledLogoutButton
          type="link"
          icon={<LogoutOutlined />}
          onClick={logoutHandler}
        >
          Logout
        </StyledLogoutButton>
      </StyledUserInfo>
      <StyledMenu
        mode="horizontal"
        defaultSelectedKeys={[location.pathname]}
        items={[
          {
            key: '/',
            label: 'Account management',
            onClick: () => navigate('/'),
          },
        ]}
      />
    </StyledHeader>
  );
};

export default NavMenu;
