import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import { css } from '@emotion/css';
import { ClientFeaturesModal } from './ClientFeaturesModal';
import ClientFeaturesAPI from '../../api/client-features';
import axios from 'axios';

export const ClientFeatures = ({ client }) => {
  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [features, setFeatures] = React.useState([]);

  const fetchClientFeatures = async () => {
    try {
      setLoading(true);
      const results = await ClientFeaturesAPI.all({ client });
      setFeatures(results);
    } catch (error) {
      if (!axios.isAxiosError(error)) console.error(error);
      notification.error({
        message: 'Error fetching client features',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientFeatures();
  }, []);

  return (
    <div className={css`margin-right: auto`}>
      <Button onClick={() => setModalVisible(true)} loading={loading}>
        Client Features ({features.length})
      </Button>
      <ClientFeaturesModal
        features={features}
        onChange={setFeatures}
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        client={client}
      />
    </div>
  );
};
