import { useState } from 'react';
import accountsAPI from '../../api/accounts';
import { notification } from 'antd';
import { useQuery } from '@tanstack/react-query';

const useAccountsList = () => {
  const [accountsType, setAccountsType] = useState('accounts');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    refetch,
  } = useQuery(
    ['accounts', accountsType, page, pageSize],
    () =>
      accountsAPI.list(accountsType, page, pageSize).catch(() => {
        notification.error({
          message: 'Error',
          description: 'Error while fetching accounts',
          duration: 0,
        });
      }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: [meters, clientOptions] = [],
    isLoading: isLoadingMeters,
  } = useQuery(
    ['meters-and-client-options'],
    () =>
      Promise.all([
        accountsAPI.listMeters().catch(() => {
          notification.error({
            message: 'Error',
            description: 'Error while fetching meters',
          });
        }),
        accountsAPI.listClients().catch(() => {
          notification.error({
            message: 'Error',
            description: 'Error while loading clients',
          });
        }),
      ]),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );

  return [
    {
      accounts,
      page,
      pageSize,
      accountsType,
      isLoading: isLoadingAccounts || isLoadingMeters,
      meters,
      clientOptions,
    },
    {
      getAccounts: refetch,
      setPage,
      setPageSize,
      setAccountsType,
    },
  ];
};

export default useAccountsList;
