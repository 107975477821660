import { Auth0Provider } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authAPI from '../api/auth';
import { authCookie, clearAuthHeaders } from '../utils';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const [domain, setDomain] = useState('');
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    const getUserInfo = async () => {
      // Clear auth token and headers every each call,
      // in order to avoid conflicts with new BID APi auth system.
      clearAuthHeaders();
      authCookie.remove();
      const { domain, client_id } = await authAPI.getAuth0Info();
      setDomain(domain);
      setClientId(client_id);
    };
    getUserInfo();
  }, []);

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
