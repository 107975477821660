import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authAPI from '../../api/auth';
import { useAuthContext } from '../../context/AuthProvider';
import { BASE_URL } from '../../api/urls';

const PublicRoute = () => {
  const { setToken, bpLogout, auth0user, auth0Logout } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserInfo = async (auth0user) => {
      if (auth0user !== undefined) {
        const { data, status } = await authAPI.checkAuth0User(auth0user.sub);
        if (status === 200) {
          setToken(data.jwt_token);
          navigate('/');
        } else if (status === 403) {
          window.location.replace(BASE_URL);
        } else {
          bpLogout();
          auth0Logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
      }
    };
    getUserInfo(auth0user);
  }, [auth0user]);

  return <></>;
};

export default PublicRoute;
