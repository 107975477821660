import { CLIENT_FEATURES_ENDPOINT } from './urls';
import axios from 'axios';
import { templateFormat } from '../utils';

async function all({ client }) {
  try {
    const url = templateFormat(CLIENT_FEATURES_ENDPOINT, { client });
    const resp = await axios.get(url);
    return resp.data?.results ?? [];
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function create({ client, feature, enabled }) {
  try {
    const url = templateFormat(CLIENT_FEATURES_ENDPOINT, { client });
    const resp = await axios.post(url, {
      client,
      feature,
      enabled: String(enabled),
    });
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function update({ client, id, enabled }) {
  try {
    const url = templateFormat(CLIENT_FEATURES_ENDPOINT, { client });
    const resp = await axios.patch(`${url}${id}/`, {
      enabled: String(enabled),
    });
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function destroy({ client, id }) {
  try {
    const url = templateFormat(CLIENT_FEATURES_ENDPOINT, { client });
    const resp = await axios.delete(`${url}${id}/`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

const ClientFeaturesAPI = {
  all,
  create,
  update,
  destroy,
};

export default ClientFeaturesAPI;
