import { FEATURES_ENDPOINT } from './urls';
import axios from 'axios';

async function lookup() {
  try {
    const resp = await axios.get(FEATURES_ENDPOINT);
    return resp.data?.results ?? [];
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function create({ name, description = '', enabled = null }) {
  try {
    const data = { name, description };
    if (enabled !== null) data.enabled = enabled;
    const resp = await axios.post(FEATURES_ENDPOINT, data);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

const FeaturesAPI = {
  lookup,
  create,
};

export default FeaturesAPI;
