import { useState } from 'react';
import { Form, notification } from 'antd';
import accountsAPI from '../../api/accounts';

const useAccountCreate = ({ accountsType }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const createAccount = (values) => {
    setIsSaving(true);
    return accountsAPI
      .post(values)
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Error while creating account',
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return [
    {
      accountsType,
      isSaving,
      form,
    },
    {
      createAccount,
    },
  ];
};

export default useAccountCreate;
