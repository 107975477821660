import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Select, Spin } from 'antd';
import accountsAPI from '../../../api/accounts';

const AdminsDropDown = ({ value, onChange, ...restProps }) => {
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    accountsAPI
      .listAdmins()
      .then((res) => {
        const results = res.map((s) => ({
          value: s.id,
          label: s.full_name,
        }));

        setOptions(results);
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Error while loading admins',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Select
      placeholder="Select admins"
      style={{ width: '100%' }}
      optionFilterProp="label"
      options={options}
      value={value}
      onChange={onChange}
      getPopupContainer={(node) => node.parentElement}
      {...restProps}
    />
  );
};

AdminsDropDown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

AdminsDropDown.defaultProps = {
  value: undefined,
  onChange: () => {},
  multiple: false,
  disabled: false,
};

export default AdminsDropDown;
