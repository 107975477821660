import React from 'react';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Footer, NavMenu } from '.';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  .ant-layout-content {
    min-height: calc(100vh - 175px);
  }
  .ant-layout-footer {
    background-color: #fff;
  }
`;

const StyledContent = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const StyledWrapper = styled(Content)`
  padding: 0 40px;
  margin-top: 40px;
`;

const AuthenticatedLayout = ({ children }) => {
  return (
    <StyledLayout>
      <NavMenu />
      <StyledWrapper>
        <StyledContent>{children}</StyledContent>
      </StyledWrapper>
      <Footer />
    </StyledLayout>
  );
};

export default AuthenticatedLayout;
