import { useEffect, useState } from 'react';
import authAPI from '../api/auth';
import { authCookie, setAuthHeaders, clearAuthHeaders } from '../utils';

const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [bpUser, setBpUser] = useState({});

  const setToken = (token) => {
    authCookie.set(token);
    setAuthHeaders();
    setIsAuth(true);
  };

  const getToken = () => authCookie.get();

  const bpLogout = () => {
    clearAuthHeaders();
    authCookie.remove();
    setIsAuth(false);
  };

  const getUserInfo = async () => {
    const resp = await authAPI.getUserInfo();
    setBpUser(resp);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      setIsAuth(true);
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      getUserInfo();
    }
  }, [isAuth]);

  return [
    { isAuth, bpUser },
    { getToken, bpLogout, setToken },
  ];
};

export default useAuth;
