import React, { useState } from 'react';
import { Table, Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { accountsColumns, trialsColumns } from './columns';
import useAccountsList from '../../hooks/accounts/useAccountsList';
import AddAccountModal from '../AddEditAccount/AddAccountModal';
import EditAccountModal from '../AddEditAccount/EditAccountModal';
import AccountsSwitch from './AccountsSwitch';

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledActionsCol = styled(Col)`
  text-align: right;
`;

const normalizeRecord = (record) => {
  return {
    ...record,
    it_admins: record.it_admins.map((v) => v.id),
    meters: record.meters.map((v) => v.id),
  };
};

const AccountsGrid = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [
    {
      accounts,
      meters,
      clientOptions,
      isLoading,
      accountsType,
      page,
      pageSize,
    },
    { setAccountsType, setPage, setPageSize, getAccounts },
  ] = useAccountsList();

  const isAccount = accountsType === 'accounts';

  const columns = isAccount ? accountsColumns : trialsColumns;

  const openEdit = (record) => {
    setSelectedRecord(normalizeRecord(record));
    setIsEditOpen(true);
  };

  const onAccountsTypeChange = (accountsType) => {
    setPage(1);
    setPageSize(10);
    setAccountsType(accountsType);
  };

  const onPaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const actions = {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <Button onClick={() => openEdit(record)} type="link">
        Edit
      </Button>
    ),
    width: 60,
  };

  return (
    <>
      <StyledRow>
        <Col span={12}>
          <AccountsSwitch
            onChange={onAccountsTypeChange}
            value={accountsType}
          />
        </Col>
        <StyledActionsCol span={12}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsAddOpen(true)}
          >
            Add {isAccount ? 'Account' : 'Trial'}
          </Button>
        </StyledActionsCol>
      </StyledRow>

      <Table
        loading={isLoading}
        dataSource={accounts?.results}
        columns={[...columns, actions]}
        rowKey="id"
        pagination={{
          onChange: onPaginationChange,
          total: accounts?.count,
          current: page,
          pageSize,
        }}
      />
      <AddAccountModal
        accountsType={accountsType}
        isOpen={isAddOpen}
        meters={meters}
        clientOptions={clientOptions}
        onClose={() => setIsAddOpen(false)}
        afterSave={() => {
          setIsAddOpen(false);
          getAccounts();
        }}
      />
      {selectedRecord && (
        <EditAccountModal
          meters={meters}
          clientOptions={clientOptions}
          accountsType={accountsType}
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          selectedRecord={selectedRecord}
          afterSave={() => {
            setIsEditOpen(false);
            getAccounts();
          }}
        />
      )}
    </>
  );
};

export default AccountsGrid;
