import React from 'react';
import { Typography } from 'antd';
import AccountsGrid from '../components/AccountsGrid/AccountsGrid';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const { Title } = Typography;

const queryClient = new QueryClient();

const Accounts = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Title level={3}>Accounts page</Title>
        <AccountsGrid />
      </div>
    </QueryClientProvider>
  );
};

export default Accounts;
