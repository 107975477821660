import axios from 'axios';
import {
  ACCOUNTS_LIST_ENDPOINT,
  ADMINS_LIST_ENDPOINT,
  CLIENTS_LIST_ENDPOINT,
  METERS_LIST_ENDPOINT,
} from './urls';

const list = async (type, page, pageSize) => {
  try {
    const params = {
      is_trial: type === 'trials',
      page: page,
      page_size: pageSize,
    };
    const resp = await axios.get(ACCOUNTS_LIST_ENDPOINT, { params });
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const post = async (values) => {
  try {
    const resp = await axios.post(ACCOUNTS_LIST_ENDPOINT, values);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const update = async (id, values) => {
  try {
    const resp = await axios.patch(`${ACCOUNTS_LIST_ENDPOINT}${id}/`, values);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const listAdmins = async () => {
  try {
    const resp = await axios.get(ADMINS_LIST_ENDPOINT);
    return resp.data.results;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const listClients = async () => {
  try {
    const resp = await axios.get(CLIENTS_LIST_ENDPOINT);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const listMeters = async () => {
  try {
    const resp = await axios.get(METERS_LIST_ENDPOINT);
    return resp.data.results;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const accountsAPI = {
  list,
  post,
  update,
  listAdmins,
  listClients,
  listMeters,
};

export default accountsAPI;
