import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const MetersDropDown = ({ value, meters, onChange, ...restProps }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!meters) return;
    const mappedOptions = meters.map((s) => ({
      value: s.id,
      label: s.name,
    }));
    setOptions(mappedOptions);
  }, [meters]);

  return (
    <Select
      placeholder="Select meters"
      style={{ width: '100%' }}
      optionFilterProp="label"
      options={options}
      value={value}
      onChange={onChange}
      getPopupContainer={(node) => node.parentElement}
      {...restProps}
    />
  );
};

MetersDropDown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

MetersDropDown.defaultProps = {
  value: undefined,
  onChange: () => {},
  multiple: false,
  disabled: false,
};

export default MetersDropDown;
