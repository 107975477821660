import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useAuth0 } from '@auth0/auth0-react';
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { logout, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [{ isAuth, bpUser }, { getToken, setToken, bpLogout }] = useAuth();

  const contextValue = {
    isBPAuthenticated: isAuth,
    bpUser,
    getToken,
    setToken,
    bpLogout,
    auth0Logout: logout,
    isAuth0Authenticated: isAuthenticated,
    loginWithRedirect,
    auth0user: user,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthProvider.defaultProps = {};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
