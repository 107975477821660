const getBaseUrl = () => {
  const domain = window.location.hostname;
  if (domain === 'localhost' || domain === '127.0.0.1') {
    return 'http://localhost:8000';
  }
  if (domain === 'backoffice.bluepoint.io') {
    return 'https://www.bluepoint.io';
  }
  return `https://${domain.replace('backoffice.', '')}`;
};

export const BASE_URL = getBaseUrl();

export const USER_INFO_ENDPOINT = `${BASE_URL}/api/general-use/v1/users/logged-user/`;

export const ACCOUNTS_LIST_ENDPOINT = `${BASE_URL}/api/general-use/v1/users/clients/`;

export const ADMINS_LIST_ENDPOINT = `${BASE_URL}/api/general-use/v1/users/3mw-users/`;

export const CLIENTS_LIST_ENDPOINT = `${BASE_URL}/api/general-use/v1/lookups/clients/`;

export const METERS_LIST_ENDPOINT = `${BASE_URL}/api/general-use/v1/users/meters/`;

export const AUTH_INFO_ENDPOINT = `${BASE_URL}/api/general-use/v1/pf-auth0/info`;

export const CHECK_USER_ENDPOINT = `${BASE_URL}/api/general-use/v1/pf-auth0/check-user`;

export const FEATURES_ENDPOINT = `${BASE_URL}/api/general-use/v1/feature-toggles/features/`;

export const CLIENT_FEATURES_ENDPOINT = `${BASE_URL}/api/general-use/v1/feature-toggles/clients/%(client)s/features/`;
