import axios from 'axios';
import {
  USER_INFO_ENDPOINT,
  AUTH_INFO_ENDPOINT,
  CHECK_USER_ENDPOINT,
} from './urls';

export class LoginError extends Error {
  constructor(originalError) {
    const message = Object.values(originalError.response.data.detail).flat();
    super(message);
    this.name = 'LoginError';
  }
}

const getUserInfo = async () => {
  try {
    const resp = await axios.get(USER_INFO_ENDPOINT);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getAuth0Info = async () => {
  try {
    const resp = await axios.get(AUTH_INFO_ENDPOINT);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const checkAuth0User = async (sub) => {
  try {
    return await axios.post(CHECK_USER_ENDPOINT, { auth0_id: sub });
  } catch (err) {
    return err.response;
  }
};

const authAPI = {
  getUserInfo,
  getAuth0Info,
  checkAuth0User,
};

export default authAPI;
