import React from 'react';
import moment from 'moment';
import { Status, DemoData } from './CustomColumns';

const commonColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 60,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 300,
  },
  {
    title: 'Bucket',
    dataIndex: 'bucket_location',
    width: 60,
  },
  {
    title: 'Internal IT Admins',
    dataIndex: 'it_admins',
    width: 500,
    render: (value) => value?.map((v) => v.full_name).join(' | '),
  },
];

export const accountsColumns = [
  ...commonColumns,
  {
    title: 'Activated/Deactivated',
    dataIndex: 'is_active',
    render: (value) => <Status value={value} />,
  },
];

export const trialsColumns = [
  ...commonColumns,
  {
    title: 'Trial Activation',
    dataIndex: 'is_active',
    render: (value) => <Status value={value} />,
  },
  {
    title: 'Trial end date',
    dataIndex: 'trial_end_date',
    render: (value) => (value ? moment(value).format('DD MMMM YYYY') : '-'),
  },
];
