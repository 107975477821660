import React from 'react';
import { css } from '@emotion/css';
import { Button, List, Popconfirm, Spin, Switch, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const ClientFeatureItem = ({ item, onChange, onDelete }) => {
  const [loading, setLoading] = React.useState(false);

  const updateFeature = async (enabled) => {
    try {
      setLoading(true);
      await onChange({ ...item, enabled });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = async () => {
    try {
      await onDelete(item);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <List.Item
      className={css`padding: 12px !important; border-radius: 4px; &:hover { background-color: #fff;}`}
      extra={
        <div
          className={css`display: flex; align-items: center; justify-content: end; gap: 8px;`}
        >
          {loading && <Spin size="small" />}
          <Switch
            defaultChecked={item.enabled}
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            onChange={updateFeature}
          />
          <Popconfirm
            title="Delete client feature"
            description="Are you sure to delete this feature from the client?"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
            okType="danger"
          >
            <Tooltip title="Delete">
              <Button
                type="link"
                danger
                size="small"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      }
    >
      {item.feature.name}
    </List.Item>
  );
};
